(function ($) {
  function getLineHeight(target) {
    const textContents = document.querySelector(target)

    // テキスト要素のline-heightを取得
    let lineHeight = getComputedStyle(textContents).getPropertyValue('line-height')
    // [32.4px]のようなピクセル値が返ってくるので、数字だけにする
    lineHeight = lineHeight.replace(/[^\d.-]/g, '')
    return lineHeight
  }

  function escape_html(string) {
    if (typeof string !== 'string') {
      return string
    }

    return string.replace(/["&'<>`]/g, match => {
      return {
        '&': '&amp;',
        '\'': '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;'
      }[match]
    })
  }

  function dtThSelectsTdRadiosCol(dataTable) {
    dataTable.rows().every(function (rowIndex) {
      const trEl = this.node()
      $(trEl).find('[data-dt="dtThSelectsTdRadiosCol"]').each((trInex, tdEl) => {
        const checkedVal = $(tdEl).find('input:checked').val() === 'true'
        if (checkedVal) {
          $(trEl).addClass('selected')
        } else {
          $(trEl).removeClass('selected')
        }
      })
    })
  }

  function dTFooterTextSearch(col) {
    const that = col
    $('<input class="form-control" type="text">')
      .appendTo($(col.footer()).empty())
      .on('keyup change clear', function () {
        that
          .search(this.value)
          .draw()
      })
  }

  function dTFooterSelectApplyToggleSearch(col, dataTable) {
    const column = col
    const select = $('<select class="form-control"><option value=""></option></select>')
          .appendTo($(column.footer()).empty())
          .on('change', function () {
            const val = $.fn.dataTable.util.escapeRegex(
              $(this).val()
            )

            column
            .search(val)
                  .draw()
          })

    column.data().unique().sort().each(d => {
      let text = d
      if (text === true) {
        text = '適用'
      } else
      if (text === false) {
        text = '未適用'
      }

      select.append('<option value="' + d + '">' + text + '</option>')
    })
  }

  $.fn.dtTdColRender = (function (data) {
    const data_array = data.split(',')
    const $container = $('<div />')
    const $row = $('<div class="row mx-auto text-center" />')
    $row.css({ minWidth: '7rem' })
    $.each(data_array, (index, value) => {
      const $elValue = $('<div class="col-4" />')
      $elValue.text(value)
      $row.append($elValue)
    })
    $container.append($row)
    return $container.html()
  })
  $.fn.dtTdOverflowRender = (function (data, myTable) {
    let str = ''
    const $container = $('<div />')
    const $elemnt = $container.append(data)
    const escape_data = escape_html($elemnt.html())
    const lineHeight = myTable.match(/#/) ? getLineHeight(myTable + '_app th') : getLineHeight('#' + myTable + '_app th')
    const height = lineHeight

    str = '<div class="td-overflow-contents d-flex"><div class="td-overflow-wrapper overflow-hidden position-relative text-wrap" style="height:' + height + 'px;"><div class="td-overflow-text">' + escape_data + '</div><div class="td-overflow-hidden" aria-hidden="true" style="display:none;">' + escape_data + '</div></div></div>'

    // 一度中身削除
    $elemnt.empty()
    $elemnt.append(str)
    // if ($elemnt.length) {
    //   $elemnt.append(str)
    //   $container.append($elemnt)
    // } else {
    //   $container.append($(str))
    // }
    $container.append($elemnt)

    return $container.html()
  })
  $.fn.dtDisabledRowCheckboxToggleRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div class="dtDivRowCheckbox"/>')
    const $inputCheckbox = $('<input type="checkbox" class="m-auto d-block position-relative z-index-1">')

    if (data.disabled === true) {
      $inputCheckbox.prop('disabled', true)
    }

    if (data.checked === true) {
      $inputCheckbox.attr('checked', 'checked')
      $containerInner.append($inputCheckbox)
    } else
    if (data.checked === false) {
      $containerInner.append($inputCheckbox)
    }

    if (data.disabledRow === true) {
      $containerInner.addClass('dtTrDisabled dtSelectedRowSelectedOff')
    }

    $container.append($containerInner)
    return $container.html()
  })

  // エージェントorサポート値引き率 会社名列
  $.fn.dtAgentSupporterCompanyColRender = (function (data) {
    const $icon = $('<span />').addClass('badge ml-md-auto ml-1')
    const $button = $('<a class="btn btn-sm btn-white text-nowrap font-xs"><i class="c-icon c-icon-xs cil-clone ml-1"></i></a>')
    const $name = $('<div />').append(data.text)
    const $container = $('<div />')
    if (data.text) {
      $container.append($name)
    }

    if (data.icon) {
      if (data.icon === '個別契約') {
        $icon.addClass('badge-blue-color')
      } else
      if (data.icon === '未来あり') {
        $icon.addClass('badge-orange-color')
      } else
      if (data.icon === '受注停止') {
        $icon.addClass('badge-gray03')
      } else
      if (data.icon === '受注停止(債権理由)') {
        $icon.addClass('badge-gray03')
      }

      $icon.append(data.icon)
      $container.append($icon)
    }

    if (data.button) {
      $button.attr('href', data.button.url)
      $button.prepend(data.button.text)
      $button.addClass('position-relative z-index-1')
      $container.append($button)
    }

    return $container.html()
  })
  $.fn.dtTdSelectOptionRender = (function (data, myTable) {
    const $container = $('<div />')
    const $select = $('<select class="form-control form-control-sm w-auto m-auto"></select>')
    if (data.datas) {
      $.each(data.datas, (index, item) => {
        const $option = $('<option />').val(item.option).append(item.option)
        $select.append($option)
      })
      if (data.disabled) {
        $select.prop('disabled', true)
      }

      $container.append($select)
      return $container.html()
    }

    return ''
  })

  // ポップオーバー
  $.fn.dtPopoverlRender = (function (data, myTable) {
    const $container = $('<div>')
    // const $containerInner = $('<div>')
    const $popover = $('<button class="ml-2 btn btn-link p-0 text-decoration-none" role="button" data-placement="top" data-toggle="popover-dt" type="button" data-html="true" data-content="' + data.text + '" data-original-title="" title=""><i class="icon-question"></i></button>')

    $container.append($popover)
    return $container.html()
  })

  // エージェント＆サポート値引率 お客様値引率
  $.fn.dtAgentSupporterCustomerDiscountRateRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div class="text-nowrap" />')
    let maxLength = ''
    if (data.datas) {
      maxLength = data.datas.length
      $.each(data.datas, (index, item) => {
        const $cell1 = $('<div />').addClass('d-flex p-1 border-bottom-dotted')
        const $cell2 = $('<div />').addClass('d-flex p-1')
        const $cellWrap = $('<div data-dt="dtElHeightAdjust" />')
        $cell1.append('<span>（一律値引率）</span>')
        $cell1.append(`<span class="ml-auto font-weight-bold">${item.text1 ? item.text1 : ''}</span>`)

        $cell2.append('<span>（カテゴリー値引率）</span>')
        $cell2.append(`<span class="ml-auto font-weight-bold">${item.text2 ? item.text2 : ''}</span>`)

        if ((maxLength - 1) !== index) {
          $cellWrap.addClass('border-bottom')
        }

        $cellWrap.append($cell1)
        $cellWrap.append($cell2)
        $containerInner.append($cellWrap)
      })
    }

    $container.append($containerInner)
    return $container.html()
  })

  // 一律/カテゴリ 切り替え
  $.fn.dtUniformCategoryToggleRender = (function (data, opts) {
    const $container = $('<div />')
    // デフォルト設定
    const optoinsDef = {
      buttonLable: '設定'
    }
    const optoins = $.extend({}, optoinsDef, opts)
    const $containerInner = $(`<div class="d-flex flex-row pt-1 px-1" data-dt="dtTdUniformCategoryToggle">
    </div>`)
    if (data) {
      const $fieldset = $(`
        <fieldset>
        <div class="align-items-center d-flex m-1 text-nowrap">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input data-dt="dtTdUniformCategoryToggleRadio" class="form-check-input" type="radio" value="1" ${data.checkedIndex === '0' ? 'checked' : ''}>一律
            </label>
          </div>
        </div>
        <div class="align-items-center d-flex m-1 text-nowrap">
          <div class="form-check form-check-inline">
            <label class="form-check-label">
              <input data-dt="dtTdUniformCategoryToggleRadio" class="form-check-input" type="radio" value="2" ${data.checkedIndex === '1' ? 'checked' : ''}>カテゴリー
            </label>
          </div>
        </div>
      </fieldset>
      `)
      const $bottons = $(`
      <div>
        <button data-dt="dtTdUniformCategoryToggleSettings" class="btn btn-sm btn-white shadow-sm mt-auto mb-auto text-nowrap my-auto" type="button" data-toggle="modal" data-target="#set_discount_rate_modal" ${data.checkedIndex === '0' ? 'disabled' : ''}>${optoins.buttonLable}</button>
      </div>
      `)
      $containerInner.append($fieldset)
      if (data.checkedIndex && !data.popover) {
        $containerInner.append($bottons)
      }

      if (data.popover) {
        const $popover = $(`<div class="m-auto"><button class="align-middle btn btn-link p-0" type="button" data-toggle="popover" data-content="${data.popover.content}" data-placement="top"><i class="c-icon c-icon-sm icon-question"></i></button></div>`)
        $containerInner.append($popover)
        $fieldset.addClass('disabled')
      }
    }

    $container.append($containerInner)
    return $container.html()
  })
  // 申請内容エージェント値引き 一律/カテゴリ ボタン
  $.fn.dtAgentSupporterUniformCategoryButtonRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div />')
    let maxLength = ''

    if (data.datas) {
      maxLength = data.datas.length
      $.each(data.datas, (index, item) => {
        const $cell1 = $('<div />')
        const $cellWrap = $('<div data-dt="dtElHeightAdjust" />').addClass('d-flex p-1')
        if (item.text === '一律') {
          $cell1.append(item.text)
        } else
        if (item.text === 'カテゴリー') {
          $cell1.append('<button class="btn btn-sm btn-white shadow-sm mt-auto mb-auto text-nowrap" type="button" data-toggle="modal" data-target="#check_discount_rate_modal">カテゴリー</button>')
        }

        if ((maxLength - 1) !== index) {
          $cellWrap.addClass('border-bottom')
        }

        $cellWrap.append($cell1)
        $containerInner.append($cellWrap)
      })
    }

    $container.append($containerInner)
    return $container.html()
  })
  // まとめ設定一覧 ボタン
  $.fn.dtIntegrateBillAddressListControlRender = (function (datas, myTable) {
    const $container = $('<div />')

    const $containerInner = $('<div />').addClass('d-flex')

    $.each(datas, (index, item) => {
      const $button = $('<button type="button" data-toggle="modal" />').addClass('btn btn-sm shadow-sm btn-primary text-nowrap')
      $button.append(item.label)

      $button.css({ width: '55px' })

      if (item.disabled) {
        $button.prop('disabled', item.disabled)
      }

      if (index === 1) {
        $button.addClass('ml-1')
      }

      if (item.label === '削除') {
        $button.attr('data-target', '#integrateBillDeleteModal').addClass('btn-white').removeClass('btn-primary')
      } else

      if (item.label === '再設定') {
        $button.attr('data-target', '#integrateBillResettingModal')
      }

      $containerInner.append($button)
    })
    $container.append($containerInner)
    return $container.html()
  })
  // まとめ設定一覧 セル
  $.fn.dtIntegrateBillAddressListCellRender = (function (data, myTable) {
    const $container = $('<div>')
    const $containerInner = $('<div>')

    if (data.datas) {
      $.each(data.datas, (index, item) => {
        const $cell = $('<div class="p-1 ' + item.className + '"  data-dt="dtElHeightAdjust" />')

        const $anker = $('<a href="' + item.url + '" class="text-accent-color underline" />')
        const dataText = item.text ? item.text : '&nbsp;'

        if (item.url) {
          $anker.append(dataText)
          if (item.text) {
            $cell.append($anker)
          } else {
            $cell.append(dataText)
          }
        } else {
          $cell.append(dataText)
        }

        if (item.tdClass) {
          $cell.attr('data-dt-tdclass', item.tdClass)
        }

        $containerInner.append($cell)
      })
    } else {
      const $cell = $('<div class="p-1 ' + data.className + '" data-dt="dtElHeightAdjust" />')

      const $anker = $('<a href="' + data.url + '" class="text-accent-color underline" />')
      const dataText = data.text ? data.text : '&nbsp;'

      if (data.url) {
        $anker.append(dataText)
        if (data.text) {
          $cell.append($anker)
        } else {
          $cell.append(dataText)
        }
      } else {
        $cell.append(dataText)
      }

      if (data.tdClass) {
        $cell.attr('data-dt-tdclass', data.tdClass)
      }

      $containerInner.append($cell)
    }

    $container.append($containerInner)
    return $container.html()
  })

  // エージェント サポート 値引率 企業ID
  $.fn.dtAgentSupporterColCompanyIdRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div />').addClass('dtColCells')
    if (data.datas) {
      $.each(data.datas, (index, item) => {
        const $cell = $(`<div class="p-1 ${item.className ? item.className : ''}" data-dt="dtElHeightAdjust" />`)
        // const $icon = $('<span class="badge ml-md-auto ml-1">' + item.icon + '</span>')
        // const $button = $('<a class="btn btn-sm btn-white text-nowrap font-xs"><i class="c-icon c-icon-xs cil-clone ml-1"></i></a>')

        const $anker = $('<a href="' + item.url + '" class="text-accent-color underline" />')
        const $dataText = item.text ? $('<span class="mr-1">' + item.text + '</span>') : '&nbsp;'
        if (item.url) {
          $anker.append($dataText)
          $anker.addClass('position-relative z-index-1')
          if (item.text) {
            $cell.append($anker)
          } else {
            $cell.append($dataText)
          }
        } else {
          $cell.append($dataText)
        }

        if (item.tdClass) {
          $cell.attr('data-dt-tdclass', item.tdClass)
        }

        $containerInner.append($cell)
      })
    } else {
      const $cell = $(`<div class="p-1 ${data.className ? data.className : ''}" data-dt="dtElHeightAdjust" />`)
      const $anker = $('<a href="' + data.url + '" class="text-accent-color underline" />')
      const dataText = data.text ? data.text : '&nbsp;'
      if (data.url) {
        $anker.append(dataText)
        $anker.addClass('position-relative z-index-1')
        if (data.text) {
          $cell.append($anker)
        } else {
          $cell.append(dataText)
        }
      } else {
        $cell.append(dataText)
      }

      if (data.tdClass) {
        $cell.attr('data-dt-tdclass', data.tdClass)
      }

      $containerInner.append($cell)
    }

    $container.append($containerInner)
    return $container.html()
  })
  // エージェント＆サポート値引率 個別セル
  $.fn.dtAgentSupporterColCellsRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div />').addClass('dtColCells')
    let maxLength = ''
    if (data.datas) {
      maxLength = data.datas.length
      $.each(data.datas, (index, item) => {
        const $cell = $(`<div class="p-1 ${item.className ? item.className : ''}" data-dt="dtElHeightAdjust" />`)
        const $icon = $('<span class="badge ml-md-auto ml-1">' + item.icon + '</span>')
        const $button = $('<a class="btn btn-sm btn-white text-nowrap font-xs"><i class="c-icon c-icon-xs cil-clone ml-1"></i></a>')

        const $anker = $('<a href="' + item.url + '" class="text-accent-color underline" />')
        const $dataText = item.text ? $('<span class="mr-1">' + item.text + '</span>') : '&nbsp;'
        if (item.url) {
          $anker.append($dataText)
          if (item.text) {
            $cell.append($anker)
          }
        } else
        if (item.text) {
          $cell.append($dataText)
        }

        if (item.icon) {
          if (item.icon === '申請あり') {
            $icon.addClass('badge-blue-color')
          } else
          if (item.icon === '未来あり') {
            $icon.addClass('badge-orange-color')
          }

          $cell.append($icon)
        }

        if (item.button) {
          $button.attr('href', item.button.url)
          $button.prepend(item.button.text)

          $cell.append($button)
        }

        if (item.tdClass) {
          $cell.attr('data-dt-tdclass', item.tdClass)
        }

        if ((maxLength - 1) !== index) {
          $cell.addClass('border-bottom')
        }

        $containerInner.append($cell)
      })
    } else {
      const $cell = $(`<div class="p-1 ${data.className ? data.className : ''}" data-dt="dtElHeightAdjust" />`)
      const $anker = $('<a href="' + data.url + '" class="text-accent-color underline" />')
      const dataText = data.text ? data.text : '&nbsp;'
      if (data.url) {
        $anker.append(dataText)
        if (data.text) {
          $cell.append($anker)
        } else {
          $cell.append(dataText)
        }
      } else {
        $cell.append(dataText)
      }

      if (data.tdClass) {
        $cell.attr('data-dt-tdclass', data.tdClass)
      }

      $containerInner.append($cell)
    }

    $container.append($containerInner)
    return $container.html()
  })
  // エージェント＆サポート値引率 個別セル エージェント値引率列
  $.fn.dtAgentSupporterAgentDiscountRateColCellsRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div />').addClass('dtColCells')
    let maxLength = ''
    if (data.datas) {
      maxLength = data.datas.length
      $.each(data.datas, (index, item) => {
        const $cell = $(`<div class="p-1 ${item.className ? item.className : ''}" data-dt="dtElHeightAdjust" />`)
        const $icon = $('<span class="badge ml-md-auto ml-1">' + item.icon + '</span>')
        const $button = $('<a class="btn btn-sm btn-white text-nowrap font-xs"><i class="c-icon c-icon-xs cil-clone ml-1"></i></a>')

        const $anker = $('<a href="' + item.url + '" class="text-accent-color underline" />')
        const $dataText = item.text ? $('<span class="mr-1">' + item.text + '</span>') : '&nbsp;'
        const $dataRate = $('<span class="mr-1">' + item.rate + '</span>')

        if (item.url) {
          $anker.append($dataText)
          if (item.text) {
            $cell.append($anker)
          } else {
            $cell.append($dataText)
          }
        }

        if (item.rate) {
          $cell.addClass('align-items-center d-flex justify-content-end')
          if (Number(item.rate.replace('%', '')) <= 3) {
            $cell.addClass('bg-pink-color')
          }

          $cell.append($dataRate)
        }

        if (item.icon) {
          if (item.icon === '申請あり') {
            $icon.addClass('badge-blue-color')
          } else
          if (item.icon === '未来あり') {
            $icon.addClass('badge-orange-color')
          }

          $cell.append($icon)
        }

        if (item.button) {
          $button.attr('href', item.button.url)
          $button.prepend(item.button.text)

          $cell.append($button)
        }

        if (item.tdClass) {
          $cell.attr('data-dt-tdclass', item.tdClass)
        }

        if ((maxLength - 1) !== index) {
          $cell.addClass('border-bottom')
        }

        $containerInner.append($cell)
      })
    } else {
      const $cell = $(`<div class="p-1 ${data.className ? data.className : ''}" data-dt="dtElHeightAdjust" />`)
      const $anker = $('<a href="' + data.url + '" class="text-accent-color underline" />')
      const dataText = data.text ? data.text : '&nbsp;'
      if (data.url) {
        $anker.append(dataText)
        if (data.text) {
          $cell.append($anker)
        } else {
          $cell.append(dataText)
        }
      } else {
        $cell.append(dataText)
      }

      if (data.tdClass) {
        $cell.attr('data-dt-tdclass', data.tdClass)
      }

      $containerInner.append($cell)
    }

    $container.append($containerInner)
    return $container.html()
  })
  // エージェント＆サポート値引率 ベースマイレージ
  $.fn.dtAgentSupporterBaseMileageRender = (function (data, myTable) {
    const $container = $('<div />')
    const $containerInner = $('<div />')
    const $cell = $('<div />').addClass('d-flex p-1')
    const $cell2 = $('<div />').addClass('d-flex p-1')
    if (data) {
      $cell.append($('<span class="mr-auto">現在</span>'))
      if (Number(data.text1.replace('%', '')) < 10) {
        $cell.append(data.text1).addClass('bg-pink-color')
      }

      $cell2.append($('<span class="mr-auto">次回</span>'))
      if (data.text2) {
        $cell2.append(data.text2).addClass('bg-palette06-color')
      }

      $containerInner.append($cell)
      $containerInner.append($cell2)
      $container.append($containerInner)
    }

    return $container.html()
  })

  // カテゴリー選択 適用/未適用 ラジオボタン
  $.fn.dtSelectApplyToggleSearchRender = (function (data, s, d, o) {
    const tag = `<div class="col-form-input">
    <div class="d-none" data-dt="dtSelectApplyToggleSearchHidden">${data}</div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" value="true" name="${o.settings.sTableId}_${o.row}_selected" ${data === true ? 'checked="checked"' : ''}>適用
      </label>
    </div>
    <div class="form-check form-check-inline">
      <label class="form-check-label">
        <input class="form-check-input" type="radio" value="false" name="${o.settings.sTableId}_${o.row}_selected" ${data === false ? 'checked="checked"' : ''}>未適用
      </label>
    </div>
  </div>`

    return tag
  })

  // カテゴリー選択 適用/未適用 全てOn/Off 切り替えボタン
  $.fn.dtCategorySelectApplyAllOnOffToggleButtonRender = (function (label) {
    const tag = `<div>${label}</div>
    <div class="d-flex">
    <button class="btn btn-primary btn-sm font-xs text-left text-nowrap" type="button" data-dt-select-apply-toggle-button="true">表示したカテゴリーを<br>全て適用に変更</button>
    <button class="btn btn-primary font-xs ml-1 text-left text-nowrap" type="button" data-dt-select-apply-toggle-button="false">表示したカテゴリーを<br>全て未適用に変更</button>
  </div>`

    return tag
  })

  $.fn.DataTableMethods = function (settings) {
    // eslint-disable-next-line unicorn/consistent-function-scoping
    function getDataMatch(datas, mstr) {
      const _tmpDatas = datas
      let result

      _tmpDatas.forEach((d, index) => {
        const datasIndex = index
        if (Array.isArray(d.data)) {
          d.data.forEach((_d, _index) => {
            if (_d === mstr) {
              _tmpDatas.push(_tmpDatas[datasIndex])
              result.data = _tmpDatas[datasIndex].data

              return false
            }
          })
        } else if (_tmpDatas.find(item => item.data === mstr)) {
          result = _tmpDatas.find(item => item.data === mstr)
          return false
        }
      })
      return result
    }

    const myTable = '#' + this.attr('id')
    const myTableApp = '#' + this.attr('id') + '_app'
    const $myTable = $(this)

    this.settingsDef = {
      oLanguage: {
      /* 日本語化設定 */
        processing: '読込中...',
        sLengthMenu: '表示　_MENU_　件', // 表示行数欄(例 = 表示 10 件)
        oPaginate: { // ページネーション欄
          sNext: '次へ',
          sPrevious: '前へ'
        },
        sInfo: '_TOTAL_ 件中 _START_件から_END_件 を表示しています', // 現在の表示欄(例 = 100 件中 20件から30件 を表示しています)
        sItem: '行 選択',
        sSearch: '検索 ', // 検索欄(例 = 検索 --)
        sZeroRecords: '表示するデータがありません', // 表示する行がない場合
        sInfoEmpty: '0 件中 0件 を表示しています', // 行が表示されていない場合
        sInfoFiltered: '全 _MAX_ 件から絞り込み' // 検索後に総件数を表示する場合
      },
      stateSave: true,
      searching: false,
      select: {
        style: 'api'
      },
      dtTextSearchBottomToTop: false, // 列テキスト検索下から上へ表示
      dtSearchFilterHidden: false, // テキスト検索フィルタ非表示
      dom: '<\'row dt_head_row\'<\'dtColumnsFilterToggle mb-2\'><\'ml-auto\'f><\'col-12\'<\'flex-wrap d-flex\'<\'d-flex my-auto\'l><\'dtHeadLengthLeft\'><\'align-items-center d-flex ml-auto mr-2\'<\'mr-2 ml-auto\'i><\'\'p>>>><\'dtHeadRadioMatch\'>>' +
      '<\'dtColumnsFilter\'>' +
      '<\'row dt_body_row\'<\'col\'tr>>' +
      '<\'dtCheck\'>' +
      '<\'flex-wrap d-flex dt_footer_row pt-1\'<\'dtFooterLeft\'><\'d-flex my-auto pl-2 dtFooterChecbox\'><\'ml-auto mr-2 align-items-center d-flex\'<\'mr-2\'i>p>>',
      dtDomTemplate: '',
      dtDomTemplates: {
        modalSetCheckDiscountRate: '<\'row dt_body_row\'<\'col\'tr>>' +
        '<\'align-items-center d-flex\'<i>p>' },
      dtTableSize: 'small', // small nomal
      dtColumnsFilterToggleBtnDisplay: false,
      // dtColumnsFilterClip: false,
      dtFixedHeader: false,
      dtScrollX: true,
      dtHeaderDisplay: true,
      dtFirstSortingHidden: false, // 最初の列並べ替えアイコン非表示
      dtTopBottomScrollbar: true, // 上下に横スクロールバー
      dtFadeIn: true,
      dtStriped: true, // 背景ストライプ
      dtHover: true, // テーブルホバー
      // dtRowspanColspan: false, // ヘッダーrowspan colspan属性を使用する場合
      dtHeadThTagSet: false, // table thタグセット
      dtDataTwoDimensionalArraySet: false, // data二次元配列でセット
      dtData: [], // data二次元配列用
      dtHeadRadioMatch: [], // 上部ラジオボタン
      dtHeadLengthLeft: { el: '', className: '' }, // 上部表示数左
      dtFooterChecbox: [], // 下部チェックボックス
      dtFooterLeft: { el: '', className: '' }, // 下部左要素
      columnDefs: [
        {
          target: 0,
          orderable: true
        }

      ],
      dtHead: [],
      // autowidth: true,
      scrollX: true,
      pageLength: 20, // 表示行数設定(例 = 10行表示)
      lengthMenu: [[20, 50, 100], [20, 50, 100]], // 表示行数変更設定(例 = 5行/10行/15行/20行)
      // sorting: [[0, 'DESC']], // ソート設定(例 = 1列目の降順でソートする)
      language: {
        select: {
          rows: {
            _: '%d 行 選択中',
            1: '1 行 選択中'
          }
        }
      },
      dtHeadTmp: {
        data: '',
        label: '',
        colFilterChecked: { checked: true },
        render(str) {
          return str
        },
        popover: {
          content: '',
          iconClass: 'icon-question'
        },
        dtExplain: {
          link: ''
        }
      }
    }

    if (settings.dtHead) {
      if (Array.isArray(settings.dtHead[0])) {
        for (let x = 0; x < settings.dtHead.length; x++) {
          this.settingsDef.dtHead.push([])
          for (let y = 0; y < settings.dtHead[x].length; y++) {
            this.settingsDef.dtHead[x].push(this.settingsDef.dtHeadTmp)
          }
        }

        // $.each(settings.dtHead, (headIndex, headValue) => {
        //   $.each(headValue, function (headIndex2, headValue2) {
        //     for (let i = 0; i < headValue2.length; i++) {
        //       this.settingsDef.dtHead[headIndex][i].push(this.settingsDef.dtHeadTmp)
        //     }
        //   })
        // })
      } else {
        for (let i = 0; i < settings.dtHead.length; i++) {
          this.settingsDef.dtHead.push(this.settingsDef.dtHeadTmp)
        }
      }
    }

    this.settings = $.extend(true, {}, this.settingsDef, settings)

    // tdテキスト長文 生成
    this.dtTdOverflowRender = function (data) {
      let str = ''
      const $container = $('<div />')
      const $elemnt = $container.append(data)
      const escape_data = escape_html($elemnt.html())
      const lineHeight = getLineHeight(myTableApp + ' th')
      const height = lineHeight

      str = '<div class="td-overflow-contents d-flex"><div class="td-overflow-wrapper overflow-hidden position-relative text-wrap" style="height:' + height + 'px;"><div class="td-overflow-text">' + escape_data + '</div><div class="td-overflow-hidden" aria-hidden="true" style="display:none;">' + escape_data + '</div></div></div>'

      // 一度中身削除
      $elemnt.empty()
      $elemnt.append(str)
      // if ($elemnt.length) {
      //   $elemnt.append(str)
      //   $container.append($elemnt)
      // } else {
      //   $container.append($(str))
      // }

      $container.append($elemnt)

      return $container.html()
    }

    // row チェックボックス
    this.dtIsDisabledRowCheckboxToggle = function () {
      const $dtDivRowCheckbox = $(myTable).find('.dtDivRowCheckbox')
      const checkboxCount = $dtDivRowCheckbox.find('input:not([disabled])').length
      $dtDivRowCheckbox.each((dtDivRowIndex, dtDivRowEl) => {
        $(dtDivRowEl).closest('tr').removeClass('selected').removeClass('dtTrDisabled').removeClass('dtSelectedRowSelectedOff')
        if ($(dtDivRowEl).find('input').prop('checked')) {
          $(dtDivRowEl).closest('tr').addClass('selected')
        } else
        if ($(dtDivRowEl).find('input').prop('checked') === false) {
          $(dtDivRowEl).closest('tr').addClass('dtSelectedRowSelectedOff')
        }

        if ($(dtDivRowEl).filter('.dtTrDisabled').length) {
          $(dtDivRowEl).closest('tr').addClass('dtTrDisabled')
        }

        if ($(dtDivRowEl).filter('.dtSelectedRowSelectedOff').length) {
          $(dtDivRowEl).closest('tr').addClass('dtSelectedRowSelectedOff')
        }
      })
      if (checkboxCount === $dtDivRowCheckbox.find('input:checked:not([disabled])').length && $dtDivRowCheckbox.find('input:checked:not([disabled])').length !== 0) {
        $(myTableApp).find('input[data-dt-checkbox="thSelectedRow"]').prop('checked', true)
      } else {
        $(myTableApp).find('input[data-dt-checkbox="thSelectedRow"]').prop('checked', false)
      }
    }

    // 行インデックス番号 追加
    this.dtAddRowIndex = function () {
      const datatable = $(myTable).DataTable()
      $.each(datatable.rows().nodes(), (index, item) => {
        $(item).attr('data-dtindex', index)
      })
    }

    // 一律/カテゴリー切り替え
    this.dtTdUniformCategoryToggle = function () {
      const datatable = $(myTable).DataTable()
      $.each(datatable.rows().nodes(), (index, row) => {
        const $dtInputRadio = $(row).find('[data-dt="dtTdUniformCategoryToggle"] [data-dt="dtTdUniformCategoryToggleRadio"]')
        const $dtSettingsBtn = $(row).find('[data-dt="dtTdUniformCategoryToggleSettings"]')
        $dtInputRadio.attr('name', 'dtTdUniformCategoryToggle' + index + '[]')

        $dtInputRadio.on('change', function () {
          if ($(this).val() === '1') {
            $dtSettingsBtn.prop('disabled', true)
          } else
          if ($(this).val() === '2') {
            $dtSettingsBtn.prop('disabled', false)
          }
        })
      })
    }

    // tdテキスト長文 切り替え
    this.dtTdOverflowToggle = function () {
      const $myTable_wrapper = $(myTable + '_wrapper')

      $myTable_wrapper.find('.td-overflow-wrapper').each(function () {
        const $target = $(this)
        const $td = $target.closest('td')
        const lineHeight = getLineHeight(myTableApp + ' th')
        const $moreBtn = $('<button class="btn btn-link btn-more-accordion text-decoration-none d-flex p-0" data-btn="close" style="margin-left: auto;height: 1.5rem;" type="button"><span data-icon="chevron" class="btn btn-white btn-sm p-1"><i class="d-block m-0"></i></span></button>')
        const $ellipsis = $('<i data-icon="ellipsis" class="cis-ellipsis"></i>')
        const $tdOverflowWrapper = $('<div class="td-overflow-wrapper overflow-hidden position-relative text-wrap" />')
        const $tdText = $target.find('.td-overflow-text')
        const $tdTextHidden = $target.find('.td-overflow-hidden')

        // 対象の要素を、高さにautoを指定し非表示で複製する
        const $clone = $target.clone()
        // 一旦テキスト内のhtmlタグ
        $target.find('.td-overflow-text').html($tdTextHidden.text())
        $clone.find('.td-overflow-text').html($tdTextHidden.text())

        $clone.css({
          display: 'none',
          position: 'absolute',
          overflow: 'visible'
        }).width($target.width()).height('auto').removeClass('overflow-hidden position-relative')
        $target.after($clone)

        $td.addClass('td-overflow position-relative')

        //

        if ($clone.height() > lineHeight) {
          $td.find('.btn-more-accordion').remove()
          $moreBtn.append($ellipsis)
          $moreBtn.find('[data-icon="chevron"] i').addClass('cil-chevron-bottom-alt').removeClass('cil-chevron-top-alt')
          $moreBtn.attr('data-btn', 'close')
          $td.find('.td-overflow-wrapper').css('height', lineHeight + 'px')
          $td.find('.td-overflow-text').html($tdText.html())
          $td.find('.td-overflow-hidden').html($tdTextHidden.html())
          // 全文表示ボタン
          $moreBtn.on('click.more', function () {
            const $td = $(this).closest('.td-overflow')
            if ($(this).attr('data-btn') === 'close') {
              $td.find('.td-overflow-wrapper').css('height', '')
              // $td.find('[data-icon]').hide()
              $(this).attr('data-btn', 'open')
              $(this).find('[data-icon="chevron"] i').addClass('cil-chevron-top-alt').removeClass('cil-chevron-bottom-alt')
            } else if ($(this).attr('data-btn') === 'open') {
              $td.find('.td-overflow-wrapper').css('height', lineHeight + 'px')
              // $td.find('[data-icon]').show()
              $(this).find('[data-icon="chevron"] i').addClass('cil-chevron-bottom-alt').removeClass('cil-chevron-top-alt')
              $(this).attr('data-btn', 'close')
            }

            event.stopPropagation()
          })
          $td.find('.td-overflow-contents').append($moreBtn)
        } else {
          $td.find('.td-overflow-contents').empty().append($tdOverflowWrapper)
          $td.find('.td-overflow-wrapper').append($tdText, $tdTextHidden)
        }

        $clone.remove()
      })
    }

    // datatable 幅調整
    this.dtAdjust = function () {
      $(myTable).DataTable().columns.adjust()
      $(myTableApp).find('.dataTables_scrollHead').scrollLeft($(myTableApp).find('.dataTables_scrollBody').scrollLeft())
    }

    // 可変幅監視 横幅調整
    this.dtVariableWidthWatch = function () {
      const watch_data = ['#sidebar']

      $.each(watch_data, (_index, _target) => {
        if (!$(_target).length) {
          return false
        }

        const watchName = 'dt_' + myTable.slice(1) + '_variablewidthWathch'
        if (!$('.' + watchName).length) {
          $(_target).addClass(watchName)
          const $watchTarget = $('.' + watchName)
          const observer = new MutationObserver(() => {
            setTimeout(() => {
              this.dtTdOverflowToggle()
              this.dtColumnsFilterChecked()

              this.dtAdjust()
            }, 300)
          })
          observer.observe($watchTarget.get(0), {
            attributes: true
          })
        }
      })
    }

    // カラム設定フィルターチェックボックス
    this.dtColumnsFilterChecked = function () {
      $(myTableApp).find('.dtColumnsFilter').each((dtColumnsFilterIndex, dtColumnsFilterEl) => {
        const $dtColumnsFilter = $(dtColumnsFilterEl)
        const $dtColumnsFilterCheckBox = $dtColumnsFilter.find('.dtColumnsFilterCheckBox')
        let total_th_w = 0
        const $myTable_wrapper = $(myTable + '_wrapper')
        const myTable_scroll_left = $myTable_wrapper.find('.dataTables_scrollBody').scrollLeft()
        const $dataTables_scroll = $myTable_wrapper.find('.dataTables_scroll')
        const myTable_scroll_offset_left = $dataTables_scroll.offset().left + $dataTables_scroll.outerWidth()
        $dtColumnsFilterCheckBox.find('input:not([value="ALL"]').each((_index, _this) => {
          const $formCheck_span = $(_this).closest('.form-check').find('span')
          const $th_dataKey = $myTable_wrapper.find('.thead-light').eq(0).find('th[data-key="' + $(_this).val() + '"]')
          const opacity_num = '0.35'
          if ($th_dataKey.length) {
            $formCheck_span.css({ opacity: opacity_num })
            if ($(_this).is(':checked')) {
              $formCheck_span.css({ opacity: '' })
              // eslint-disable-next-line no-unused-vars
              const thPosLeft = $th_dataKey.position().left
              total_th_w += $th_dataKey.outerWidth()

              if (myTable_scroll_left > total_th_w) {
                $formCheck_span.css({ opacity: opacity_num })
              } else if (myTable_scroll_offset_left < $th_dataKey.offset().left) {
                $formCheck_span.css({ opacity: opacity_num })
              }
            }
          }
        })
      })
    }

    // カラム設定フィルター生成
    this.dtColumnsFilterInit = function () {
      const { dtHead } = this.settings
      $(myTableApp).find('.dtColumnsFilter').each((dtColumnsFilterIndex, dtColumnsFilterEl) => {
        const $dtColumnsFilter = $(dtColumnsFilterEl)
        $dtColumnsFilter.addClass('my-2').css('display', 'none')
        const $dtColumnsFilterContainer = $('<div class="dtColumnsFilterContainer">' +
        '<div class="border position-relative px-2 py-1">' +
        // '<div class="font-weight-bold">表示項目' +
        // '<input class="icon-toggle dtColumnsFilterClip" type="checkbox"><i class="align-self-center c-icon c-icon-xl cil-paperclip ml-2"></i>' +
        // '<button class="align-self-center border-0 c-icon c-icon-lg cil-x-circle dtColumnsFilterClose icon-toggle ml-2 p-0" type="button">' +
        // '</div>' +
        '<div class="dtColumnsFilterCheckBox" />' +
        '</div>' +
        '</div>' +
        '</div>')

        // if (!this.settings.dtColumnsFilterClip) {
        //   $dtColumnsFilterContainer.find('.dtColumnsFilterClip').hide()
        //   $dtColumnsFilterContainer.find('.cil-paperclip').hide()
        // }

        const $form_check_checkbox = $('<div class="form-group row mb-0 mx-0" />')

        // 全て選択用
        $form_check_checkbox.append($('<div class="form-check col-6 col-sm-4 col-md-3 col-lg-2"><label class="form-check-label"><input class="form-check-input" type="checkbox" value="ALL" checked> <span>全て</span></label></div>'))

        $.each(dtHead, (index, head) => {
          if ($.isArray(head)) {
            $.each(head, (index2, head2) => {
              const $formCheck = $('<div class="form-check col-6 col-sm-4 col-md-3 col-lg-2"><label class="form-check-label"><span></span></label></div>')
              const $formCheckInput = $('<input class="form-check-input" type="checkbox" value="">')
              if (head2.colFilterChecked.checked) {
                $formCheckInput.prop('checked', true)
              }

              $formCheck.find('label span').append(head2.label)
              $formCheckInput.val(head2.data)
              $formCheck.find('label').prepend($formCheckInput)
              if (head2.dtColumnsFilterCheckedDisplayNone) {
                $formCheck.addClass('d-none')
              }

              $form_check_checkbox.append($formCheck)
            })
          } else {
            const $formCheck = $('<div class="form-check col-6 col-sm-4 col-md-3 col-lg-2"><label class="form-check-label"><span></span></label></div>')
            const $formCheckInput = $('<input class="form-check-input" type="checkbox" value="">')
            if (head.colFilterChecked.checked) {
              $formCheckInput.prop('checked', true)
            }

            $formCheck.find('label span').append(head.label)
            $formCheckInput.val(head.data)
            $formCheck.find('label').prepend($formCheckInput)
            if (head.dtColumnsFilterCheckedDisplayNone) {
              $formCheck.addClass('d-none')
            }

            $form_check_checkbox.append($formCheck)
          }
        })

        $dtColumnsFilterContainer.find('.dtColumnsFilterCheckBox').append($form_check_checkbox)

        if ($form_check_checkbox.find('input:not([value=ALL]):not(:checked)').length) {
          $form_check_checkbox.find('[value="ALL"]').prop('checked', false)
        }

        // $(myTableApp).prepend($dtColumnsFilter)

        $dtColumnsFilter.append($dtColumnsFilterContainer)
      })
    }

    // カラム設定フィルター固定
    this.dtColumnsFilterFixed = function () {
      $(myTableApp).find('.dtColumnsFilter').each((dtColumnsFilterIndex, dtColumnsFilterEl) => {
        const $dtColumnsFilter = $(dtColumnsFilterEl)
        const $dtColumnsFilterContainer = $dtColumnsFilter.find('.dtColumnsFilterContainer')
        const scrollTop = $(window).scrollTop()
        const dbSettingPos = $dtColumnsFilter.offset().top

        const $dtScroll = $(myTableApp).find('.dataTables_scroll')
        const targetPos = $dtScroll.offset().top - ($('header.c-header-fixed').length ? $('header').outerHeight() : 0)
        const targetAreaPos = targetPos + $dtScroll.outerHeight()

        if (!$dtColumnsFilter.find('.dtColumnsFilterClip').prop('checked')) {
          $dtColumnsFilterContainer.removeClass('dtColumnsFilter-absolute')
          $dtColumnsFilterContainer.removeClass('dtColumnsFilter-fixed')
          $dtColumnsFilterContainer.css('width', '')
          $dtColumnsFilter.css('height', '')
          return false
        }

        $dtColumnsFilter.css('height', $dtColumnsFilterContainer.outerHeight() + 20)
        $dtColumnsFilterContainer.css('width', $dtColumnsFilter.css('width'))
        $dtColumnsFilterContainer.css('width', $dtColumnsFilter.outerWidth())

        $dtColumnsFilterContainer.addClass('dtColumnsFilter-absolute')
        if (scrollTop <= dbSettingPos) {
          $dtColumnsFilterContainer.removeClass('dtColumnsFilter-fixed')
        } else if (scrollTop >= dbSettingPos && scrollTop < targetAreaPos) {
          $dtColumnsFilterContainer.removeClass('dtColumnsFilter-absolute')
          $dtColumnsFilterContainer.addClass('dtColumnsFilter-fixed')
        } else {
          $dtColumnsFilterContainer.addClass('dtColumnsFilter-absolute')
          $dtColumnsFilterContainer.removeClass('dtColumnsFilter-fixed')
        }
      })
    }

    // テーブルタグ初期
    this.dtTableInit = function () {
      if ($(myTable + '_wrapper').length) {
        return false
      }

      // テーブルTHタグ
      if (!this.settings.dtHeadThTagSet) {
        $(myTable).find('thead').remove()
        $(myTable).append('<thead class="thead-light"></thead>')
      }

      const $myTable = $(myTable)
      const _settingsHead = this.settings.dtHead
      let $th

      $(myTable).addClass('table table-bordered w-100 datatable mt-0 dt-responsive dataTable')

      // テーブルストライプ
      if (this.settings.dtStriped) {
        $(myTable).addClass('table-striped')
      }

      // テーブルホバー
      if (this.settings.dtHover) {
        $(myTable).addClass('table-hover')
      }

      // テーブルサイズ
      switch (this.settings.dtTableSize) {
        case 'small':

          $(myTable).addClass('table-sm table-xsm')
          break
        default:
          break
      }

      $(myTable).wrap('<div id="' + myTableApp.slice(1) + '" />')

      if (this.settings.dtFadeIn) {
        $(myTableApp).addClass('fade-in')
      }

      // thタグ 折り返し禁止
      $(myTableApp).addClass('dtThNoWrap')

      // 横スクロール
      if (this.settings.dtScrollX) {
        // $myTable.addClass('nowrap')
        this.settings.scrollX = true
        // this.settings.autowidth = true
      } else {
        $myTable.removeClass('nowrap')
        this.settings.scrollX = true
        // this.settings.autowidth = false
      }

      if (this.settings.dtHead.length) {
        if (Array.isArray(_settingsHead[0])) {
          $.each(_settingsHead, (_settingsHeadIndex, _settingsHeadValue) => {
            const $tr = $('<tr />')
            $.each(_settingsHeadValue, (_settingsHeadIndex2, head) => {
              let str = head.label

              if (head.dtExplain.link) {
                str += '<a href="' + head.dtExplain.link + '" class="ml-2" target="_blank"><i class="cil-zoom-in"></i></a>'
              }

              if (head.popover.content) {
                str += '<button class="ml-2 btn btn-link p-0 text-decoration-none" data-placement="top" data-toggle="popover-dt" data-html="true" data-content="' + head.popover.content + '" type="button"><i class="' + head.popover.iconClass + '"></i></button>'
              }

              if (head.render) {
                $th = $('<th scope="col" data-key="' + head.data + '">' + head.render(str) + '</th>')
              } else if (!head.render) {
                $th = $('<th scope="col" data-key="' + head.data + '">' + str + '</th>')
              }

              if (head.rowspan) {
                $th.attr('rowspan', head.rowspan)
              }

              if (head.colspan) {
                $th.attr('colspan', head.colspan)
              }

              if (head.className) {
                $th.addClass(head.className)
              }

              $tr.append($th)
            })
            $(myTable).find('.thead-light').append($tr)
          })
        } else {
          const $tr = $('<tr />')
          $.each(_settingsHead, (_settingsHeadIndex, head) => {
            let str = head.label

            if (head.dtExplain.link) {
              str += '<a href="' + head.dtExplain.link + '" class="ml-2" target="_blank"><i class="cil-zoom-in"></i></a>'
            }

            if (head.popover.content) {
              str += '<button class="ml-2 btn btn-link p-0 text-decoration-none" data-placement="top" data-toggle="popover-dt" data-html="true" data-content="' + head.popover.content + '" type="button"><i class="' + head.popover.iconClass + '"></i></button>'
            }

            if (head.render) {
              $th = $('<th scope="col" data-key="' + head.data + '">' + head.render(str) + '</th>')
            } else if (!head.render) {
              $th = $('<th scope="col" data-key="' + head.data + '">' + str + '</th>')
            }

            if (head.className) {
              $th.addClass(head.className)
            }

            $tr.append($th)
          })
          $(myTable).find('.thead-light').append($tr)
        }
      }

      $(myTable).find('[data-toggle="popover-dt"]').each((index, element) => {
        // eslint-disable-next-line no-new
        // new coreui.Popover(element)
        // バブリング停止
        $(element).on('click.popover', event => {
          const $popover = $(event.currentTarget)
          $popover.popover('show')
          event.stopPropagation()
        })
      })

      // 最初の列並べ替え非表示
      if (this.settings.dtFirstSortingHidden) {
        $(myTable).addClass('dtFirstSortingHidden')
        this.settings.columnDefs[0].targets = 0
        this.settings.columnDefs[0].orderable = false
      }

      // テンプレートdom
      for (const key of Object.keys(this.settings.dtDomTemplates)) {
        if (key === this.settings.dtDomTemplate) {
          this.settings.dom = this.settings.dtDomTemplates[key]
        }
      }
    }

    // データテーブルヘッダ カラムセット
    this.dtHeadColumnsSet = function () {
      const _columns_data = []
      const _settingsColumns = this.settings.columns
      const { dtTdOverflowRender } = this
      if (this.settings.dtHead.length) {
        if (Array.isArray(this.settings.dtHead[0]) && this.settings.dtHead.length) {
          $.each(this.settings.dtHead, (dtHeadXIndex, dtHeadXData) => {
            $.each(dtHeadXData, (index, data) => {
              let _tmpSetting = {}
              if (_settingsColumns) {
                if (getDataMatch(_settingsColumns, data.data)) {
                  _tmpSetting = getDataMatch(_settingsColumns, data.data)

                  if (_tmpSetting.dtTdOverflowRender) {
                    _tmpSetting.render = dtTdOverflowRender
                  }
                }
              }

              _tmpSetting.data = data.data

              _columns_data.push(_tmpSetting)
            })
          })
        } else {
          $.each(this.settings.dtHead, (index, data) => {
            let _tmpSetting = {}
            if (_settingsColumns) {
              if (getDataMatch(_settingsColumns, data.data)) {
                _tmpSetting = getDataMatch(_settingsColumns, data.data)

                if (_tmpSetting.dtTdOverflowRender) {
                  _tmpSetting.render = dtTdOverflowRender
                }
              }
            }

            _tmpSetting.data = data.data

            _columns_data.push(_tmpSetting)
          })
        }
      }

      if (this.settings.dtDataTwoDimensionalArraySet) {
        $.each(this.settings.data, (xIndex, xValue) => {
          this.settings.dtData.push([])
          $.each(xValue, (index, value) => {
            this.settings.dtData[xIndex].push(value)
          })
        })
        this.settings.data = this.settings.dtData
      } else {
        // this.settings.columns = this.settings.columns ? this.settings.columns : _columns_data
        this.settings.columns = _columns_data
      }
    }

    // 上下スクロールバー切替
    this.dtTopBottomScrollbarToggle = function () {
      if (!this.settings.dtTopBottomScrollbar) {
        return false
      }

      const $myTable_wrapper = $(myTable + '_wrapper')
      const $dtScroll = $myTable_wrapper.find('.dataTables_scroll')
      const targetPos = $dtScroll.offset().top - (($('header.c-header-fixed').length ? $('header').outerHeight() : 0) - 6)
      const targetAreaPos = (targetPos) + ($dtScroll.outerHeight())
      const scrollPos = $(window).scrollTop()
      if (scrollPos > targetPos && scrollPos < targetAreaPos) {
        $(myTableApp).find('.dataTables_scrollBody').attr('data-dtScrollbar', 'bottom')
      } else {
        $(myTableApp).find('.dataTables_scrollBody').attr('data-dtScrollbar', 'top')
      }
    }

    // テーブル見出し追従切替
    this.dtHeadFixed = function () {
      if (!this.settings.dtFixedHeader) {
        return false
      }

      const $myTable_wrapper = $(myTable + '_wrapper')
      const $dtScroll = $myTable_wrapper.find('.dataTables_scroll')
      const $dtScrollHeadInnerHeight = $myTable_wrapper.find('.dtScrollHeadInnerHeight')
      const $scrollHead = $myTable_wrapper.find('.dataTables_scrollHead')
      const $scrollHeadInner = $myTable_wrapper.find('.dataTables_scrollHeadInner')
      const targetPos = $dtScroll.offset().top - (($('header.c-header-fixed').length ? $('header').outerHeight() : 0) - 6)
      const targetAreaPos = (targetPos) + ($dtScroll.outerHeight())
      const scrollPos = $(window).scrollTop()
      $scrollHeadInner.addClass('dataTables_scrollHeadInner_absolute')
      $scrollHead.css('height', $scrollHeadInner.outerHeight() + 'px')
      $scrollHeadInner.css('left', '')
      $scrollHead.css('width', $myTable_wrapper.find('.dataTables_scrollBody').width() + 'px')
      $dtScrollHeadInnerHeight.css('height', $myTable_wrapper.find('.dataTables_scrollHeadInner').height())
      $scrollHead.css('position', 'absolute')
      if (scrollPos > targetPos && scrollPos < targetAreaPos) {
        // if (this.settings.dtColumnsFilterToggleBtnDisplay) {
        $scrollHead.css('top', ($('header.c-header-fixed').length ? $('header').outerHeight() : 0) - 6 + 'px')
        $scrollHead.addClass('dataTables_scrollHead_fixed')
        $scrollHead.css('position', 'fixed')
        // } else {
        //   $scrollHead.css('top', (scrollPos + $('header').outerHeight()) - ($('header').outerHeight() + $dtScroll.offset().top + 6) + 'px')
        // }
      } else {
        $scrollHead.css('top', '')
        $scrollHead.removeClass('dataTables_scrollHead_fixed')
      }
    }

    // 列固定
    this.dtThTdSticky = function () {
      $(myTableApp).find('.dtThTdSticky').each((index, el) => {
        $(el).css('left', '')
        const { left } = $(el).position()
        $(el).css('left', left)
      })
    }

    // TDクラス追加
    this.dtAddTdClass = function () {
      const datatable = $(myTable).DataTable()
      $.each(datatable.rows().nodes(), (nodeIndex, nodeItem) => {
        $.each($(nodeItem).find('[data-dt-tdclass]'), (dataIndex, dataItem) => {
          const tdClass = $(dataItem).attr('data-dt-tdclass')
          $(dataItem).closest('td').addClass(tdClass)
        })
      })
    }

    // TD内高さ調整
    this.dtElHeightAdjust = function () {
      const datatable = $(myTable).DataTable()
      $.each(datatable.rows().nodes(), (rowIndex, rowEl) => {
        // $(rowEl).find('td').find('[data-dt="dtElHeightAdjust"]').height('')
        $(rowEl).find('td').find('[data-dt="dtElHeightAdjust"]').css('min-height', '')
        $.each($(rowEl).find('td'), (tdIndex, tdEl) => {
          $.each($(tdEl).find('[data-dt="dtElHeightAdjust"]'), (cellIndex, cellEl) => {
            let maxHeight = 0
            const currentHeight = $(cellEl).outerHeight()
            if (currentHeight >= maxHeight) {
              maxHeight = currentHeight
              $.each($(rowEl).find('td'), (tdIndex2, tdEl2) => {
                $(tdEl2).find('[data-dt="dtElHeightAdjust"]').eq(cellIndex).css('min-height', maxHeight)
              })
            }
          })
        })
      })
    }

    // 列テキスト検索
    this.dtColTextSearch = function (col) {
      const datatable = $(myTable).DataTable()
      const $dtTextSearch = $(myTableApp).find('.dataTables_scrollFoot').find('[data-dt="text-search"]')
      $dtTextSearch.on('keyup change clear', function () {
        const dtTextSearchIndex = $(myTableApp).find('.dataTables_scrollFoot').find('th').index($(this).closest('th'))
        datatable.columns(dtTextSearchIndex).search(this.value).draw()
      })
    }

    // データテーブル実行
    this.dtStart = function () {
      const { dtTdOverflowToggle } = this
      try {
        if (this.settings.dtHead.length) {
          $(myTable).DataTable().destroy()
        }
      } catch (error) {
        console.log(error.message)
      }
      // }

      const dataTable = $(myTable).DataTable(this.settings)
      const { nTableWrapper } = dataTable.context[0]

      if (!this.settings.dtHeaderDisplay) {
        $(myTableApp).find('.dataTables_scroll').addClass('dataTables_scroll_scrollHeadHidden')
      }

      $(myTableApp).find('.dataTables_scrollHead').after('<div class="dtScrollHeadInnerHeight" />')
      $(myTableApp).find('.dataTables_scrollBody').on('scroll', () => {
        this.dtColumnsFilterChecked()
      })
      $(myTableApp).find('.dataTables_scrollHead').find('th[data-key]').on('focus click', event => {
        let dtHeadSrollLeft = 0
        dtHeadSrollLeft = $(myTableApp).find('.dataTables_scrollHead').scrollLeft()
        $(myTableApp).find('.dataTables_scrollBody').scrollLeft(dtHeadSrollLeft)
      })
      $(myTableApp).find('select[name=myTable_length]').on('change', event => {
        let dtHeadSrollLeft = 0
        dtHeadSrollLeft = $(myTableApp).find('.dataTables_scrollHead').scrollLeft()
        $(myTableApp).find('.dataTables_scrollBody').scrollLeft(dtHeadSrollLeft)
      })

      $(myTableApp).find('.dataTables_scrollBody').addClass('scroll-x scroll-y pb-0')
      this.dtColumnsFilterChecked()

      $('a[data-toggle="tab"]').on('shown.coreui.tab', e => {
        $(myTable).DataTable().columns.adjust().draw()
      })

      // data属性追加(columnDefs版)
      $.each(dataTable.init().columnDefs, (indexColumnDefs, itemColumnDefs) => {
        if (itemColumnDefs.dataName) {
          const { dataName } = itemColumnDefs
          $.each(itemColumnDefs.targets, (indexTargets, itemTargets) => {
            $(dataTable.columns(itemTargets).header()).attr('data-dt', dataName)
            $(dataTable.columns(itemTargets).footer()).attr('data-dt', dataName)
            $(dataTable.columns().column(itemTargets).nodes()).attr('data-dt', dataName)
          })
        }
      })
      // data属性追加(columns版)
      $.each(dataTable.init().columns, (indexColumns, itemColumns) => {
        if (itemColumns.dataName) {
          const { dataName } = itemColumns

          $(dataTable.columns(indexColumns).header()).attr('data-dt', dataName)
          $(dataTable.columns(indexColumns).footer()).attr('data-dt', dataName)
          $(dataTable.columns().column(indexColumns).nodes()).attr('data-dt', dataName)
        }
      })

      dataTable.rows().every(function (rowIndex) {
        const trIndex = rowIndex
        $(this.node()).find('td[data-dt="dtThSelectsTdRadiosCol"]').each((indexTd, elTd) => {
          $(elTd).on('change', 'input', e => {
            const $targetEl = $(e.currentTarget)
            const $targetRow = $targetEl.closest('tr')
            const $targetTd = $targetEl.closest('td')
            const cellIndex = $targetRow.find('td').index($targetTd)
            const checkedVal = $targetEl.filter(':checked').val() === 'true'
            const scrollPositionY = $(myTableApp).find('.dataTables_scrollBody').scrollTop()
            if (checkedVal) {
              $targetRow.addClass('selected')
            } else {
              $targetRow.removeClass('selected')
            }

            dataTable.cell(dataTable.row(trIndex), cellIndex).data(Boolean(checkedVal)).draw()
            $(myTableApp).find('.dataTables_scrollBody').scrollTop(scrollPositionY)
          })
        })
      })

      dataTable.columns().every(index => {
        if ($(dataTable.columns(index).footer()).get(0)) {
          if ($(dataTable.columns(index).footer()).filter('[data-dt="dtThSearch"]').length) {
            dTFooterTextSearch(dataTable.columns().column(index))
          } else
          if ($(dataTable.columns(index).footer()).filter('[data-dt="dtThSelectsTdRadiosCol"]').length) {
            dTFooterSelectApplyToggleSearch(dataTable.columns().column(index))
          }
        }
      })

      if (this.settings.select.style === 'single') {
        $(myTableApp).find('.thead-light th').removeClass('dtSelectItem')

        $(myTable).DataTable().on('select deselect draw', () => {
          $(myTable).DataTable().rows().every(function () {
            const $tr = $(this.node())
            if ($tr.is('.selected')) {
              $tr.find('input').prop('checked', true)
            } else {
              $tr.find('input').prop('checked', false)
            }
          })
        })
      } else if (this.settings.select.style === 'multi') {
        $(myTable).DataTable().on('select deselect draw', () => {
          const all = $(myTable).DataTable().rows(':visible', { search: 'applied' }).count() // get total count of rows
          const selectedRows = $(myTable).DataTable().rows(':visible', { selected: true, search: 'applied' }).count() // get total count of selected rows

          if (selectedRows < all) {
            $(myTableApp).find('th.dtSelectItem input').prop('checked', false)
          } else {
            $(myTableApp).find('th.dtSelectItem input').prop('checked', true)
          }

          $(myTable).DataTable().rows().every(function () {
            const $tr = $(this.node())
            if ($tr.is('.selected')) {
              $tr.find('input').prop('checked', true)
            } else {
              $tr.find('input').prop('checked', false)
            }
          })
        })

        $(myTableApp).find('th.dtSelectItem').on('click', () => {
          const all = $(myTable).DataTable().rows(':visible', { search: 'applied' }).count() // get total count of rows
          const selectedRows = $(myTable).DataTable().rows(':visible', { selected: true, search: 'applied' }).count() // get total count of selected rows

          if (selectedRows < all) {
          // Added search applied in case user wants the search items will be selected
            $(myTable).DataTable().rows(':visible', { search: 'applied' }).deselect()
            $(myTable).DataTable().rows(':visible', { search: 'applied' }).select()
          } else {
            $(myTable).DataTable().rows(':visible', { search: 'applied' }).deselect()
          }
        })
      }

      // テーブル上部に横スクロールバー
      if (this.settings.dtTopScrollbar === true) {
        $(myTableApp).find('.dataTables_scrollBody').addClass('dzTopScrollbar')
      }

      // テーブル上下に横スクロールバー
      if (this.settings.dtTopBottomScrollbar === true) {
        $(myTableApp).find('.dataTables_scrollBody').addClass('dzTopBottomScrollbar').attr('data-dtScrollbar', 'top')
      }

      // 上部ラジオボタン
      if (this.settings.dtHeadRadioMatch.length) {
        (dtHeadRadioMatch => {
          const $dtHeadRadioMatchInner = $(`
          <div class="ml-3">
          </div>`)
          $.each(dtHeadRadioMatch, (dtHeadRadioMatchIndex, dtHeadRadioMatchItem) => {
            const $dtFormCheck = $(`
            <div class="form-check form-check-inline">
              <label class="form-check-label">
                <input class="form-check-input" type="radio" value>
              </label>
            </div>`)
            if (dtHeadRadioMatchItem.label) {
              $dtFormCheck.find('.form-check-label').append(dtHeadRadioMatchItem.label)
            }

            if (dtHeadRadioMatchItem.name) {
              $dtFormCheck.find('input').attr('name', dtHeadRadioMatchItem.name)
            }

            if (dtHeadRadioMatchItem.checked) {
              $dtFormCheck.find('input').prop('checked', true)
            }

            if (dtHeadRadioMatchItem.value) {
              $dtFormCheck.find('input').val(dtHeadRadioMatchItem.value)
            }

            $dtHeadRadioMatchInner.append($dtFormCheck)
          })
          $(myTableApp).find('.dtHeadRadioMatch').append($dtHeadRadioMatchInner)
        })(this.settings.dtHeadRadioMatch)
      }

      // 下部チェックボックス
      if (this.settings.dtFooterChecbox.length) {
        (dtFooterChecbox => {
          const $dtFooterChecboxInner = $(`
          <div class="form-check">
            <label class="form-check-label"><input class="form-check-input" type="checkbox"></label>
          </div>`)
          $.each(dtFooterChecbox, (dtFooterChecboxIndex, dtFooterChecboxItem) => {
            if (dtFooterChecboxItem.text) {
              $dtFooterChecboxInner.find('.form-check-label').append(dtFooterChecboxItem.text)
            }

            if (dtFooterChecboxItem.value) {
              $dtFooterChecboxInner.find('input').val(dtFooterChecboxItem.value)
            }

            if (dtFooterChecboxItem.checked) {
              $dtFooterChecboxInner.find('input').prop('checked', dtFooterChecboxItem.checked)
            }
          })

          $(myTableApp).find('.dtFooterChecbox').append($dtFooterChecboxInner)
        })(this.settings.dtFooterChecbox)
      }

      // 列テキスト検索下から上へ表示
      if (this.settings.dtTextSearchBottomToTop) {
        $(myTableApp).find('.dataTables_scrollBody').prev().append($(myTableApp).find('.dataTables_scrollFoot'))

        $(myTableApp).find('tfoot th').removeClass('p-0')

        // テキスト検索結果、一旦リセット
        $(myTable).DataTable().columns().search('').draw()
      }

      // モーダル
      $(myTableApp).closest('.modal').on('shown.coreui.modal', () => {
        const scrollTop = $(myTableApp).find('.dataTables_scrollBody').scrollTop()
        $(myTable).DataTable().columns.adjust().draw()
        $(myTableApp).find('.dataTables_scrollBody').scrollTop(scrollTop)
      })

      // 表示変更時
      $(myTable).on('init.dt', (e, setting) => {
        // setTimeout(() => {
        dtThSelectsTdRadiosCol(dataTable)
        // }, 300)
      })

      // ソート変更時
      $(myTable).on('order.dt', (e, setting) => {
        // setTimeout(() => {
        dtFlowCheckbox()
        dtThSelectsTdRadiosCol(dataTable)
        dtTdOverflowToggle()
        this.dtAdjust()
        // }, 300)
      })
      // 表示件数変更時,ページ変更時,検索時
      $(myTable).on('length.dt page.dt search.dt', (e, setting) => {
        // const $paginationParent = $(myTableApp).find('.dt_head_row .pagination').parent()
        // if (setting._iDisplayLength >= 15 || setting._iDisplayLength === -1) {
        //   $paginationParent.show()
        // } else {
        //   $paginationParent.hide()
        // }

        setTimeout(() => {
          dtFlowCheckbox()
          dtThSelectsTdRadiosCol(dataTable)
          dtTdOverflowToggle()
          this.dtIsDisabledRowCheckboxToggle()
          this.dtAdjust()
          this.dtElHeightAdjust()
        }, 300)
      })

      // バブリング
      $(myTable).on('click.dt', 'button a', e => {
        e.stopPropagation()
      })

      // 読込中
      $(myTableApp).find('.dataTables_processing').each((processIndex, processEl) => {
        $(processEl).addClass('bg-primary font-weight-bold')
        $(processEl).prepend(`<div class="my-0 sk-circle">
        <div class="sk-circle1 sk-child"></div>
        <div class="sk-circle2 sk-child"></div>
        <div class="sk-circle3 sk-child"></div>
        <div class="sk-circle4 sk-child"></div>
        <div class="sk-circle5 sk-child"></div>
        <div class="sk-circle6 sk-child"></div>
        <div class="sk-circle7 sk-child"></div>
        <div class="sk-circle8 sk-child"></div>
        <div class="sk-circle9 sk-child"></div>
        <div class="sk-circle10 sk-child"></div>
        <div class="sk-circle11 sk-child"></div>
        <div class="sk-circle12 sk-child"></div>
        </div>`)
        $(processEl).wrapInner('<div class="dtProcessingInner">')
      })

      // dtHeadLengthLeft 上部表示数左
      if (this.settings.dtHeadLengthLeft.el) {
        $(myTableApp).find('.dtHeadLengthLeft').addClass(this.settings.dtHeadLengthLeft.className)
        $(myTableApp).find('.dtHeadLengthLeft').append(this.settings.dtHeadLengthLeft.el)
      }

      // dtFooterLeft 下部左要素
      if (this.settings.dtFooterLeft.el) {
        $(myTableApp).find('.dtFooterLeft').addClass(this.settings.dtFooterLeft.className)
        $(myTableApp).find('.dtFooterLeft').append(this.settings.dtFooterLeft.el)
      }

      // 検索フィルタ非表示
      if (this.settings.dtSearchFilterHidden) {
        $(myTableApp).find('.dataTables_filter').addClass('d-none')
      }

      // THチェックボックス
      $(myTableApp).on('click', '[data-dt-checkbox="thSelectedRow"]', event => {
        const $thSelectedRow = $(event.currentTarget)
        const $dtDivRowCheckbox = $(myTable).find('.dtDivRowCheckbox input:not([disabled])')
        const checked = $thSelectedRow.prop('checked')

        $dtDivRowCheckbox.closest('tr').removeClass('selected').removeClass('dtSelectedRowSelectedOff')
        if (checked) {
          $dtDivRowCheckbox.prop('checked', true)
          $dtDivRowCheckbox.closest('tr').addClass('selected')
        } else {
          $dtDivRowCheckbox.prop('checked', false)
          $dtDivRowCheckbox.closest('tr').addClass('dtSelectedRowSelectedOff')
        }
      })

      // TDチェックボックス
      $(myTable).find('.dtDivRowCheckbox').on('click', 'input:not([disabled])', event => {
        const $inputCheckbox = $(event.currentTarget)
        const $tr = $inputCheckbox.closest('tr')
        const checked = $inputCheckbox.prop('checked')
        const $dtDivRowCheckbox = $(myTable).find('.dtDivRowCheckbox input:not([disabled])')
        const checkboxCount = $dtDivRowCheckbox.length
        $tr.removeClass('selected').removeClass('dtSelectedRowSelectedOff')
        if (checked) {
          $tr.addClass('selected')
        } else {
          $tr.addClass('dtSelectedRowSelectedOff')
        }

        if (checkboxCount === $dtDivRowCheckbox.filter(':checked').length) {
          $(myTableApp).find('input[data-dt-checkbox="thSelectedRow"]').prop('checked', true)
        } else {
          $(myTableApp).find('input[data-dt-checkbox="thSelectedRow"]').prop('checked', false)
        }
      })

      // 行checked 絞り込みボタン
      $(myTableApp).find('[data-dt="dtRowCheckedFilterButton"]').on('change', event => {
        const btnChecked = $(event.currentTarget).prop('checked')

        $(myTable).DataTable().rows().every(function () {
          const $tr = $(this.node())

          if (btnChecked) {
            if ($tr.filter('.selected').length) {
              $tr.removeClass('d-none')
            } else {
              $tr.addClass('d-none')
            }
          } else {
            $tr.removeClass('d-none')
          }
        })
        $(myTable).DataTable().columns.adjust()
      })

      $(myTableApp).find('[data-dt="dtSelectApplyToggleSearchSelected"]').on('click', () => {
        dataTable.columns().every(index => {
          const columnFooter = dataTable.columns().column(index).footer()
          let valueText = ''
          if ($(columnFooter).filter('[data-dt="dtThSelectsTdRadiosCol"]').length) {
            valueText = true
            $(columnFooter).find('select').val(String(valueText))
            dataTable.columns(index).search(valueText).draw()
          }

          if ($(columnFooter).filter('[data-dt="dtThSearch"]').length) {
            valueText = ''
            $(columnFooter).find('input').val(String(valueText))
            dataTable.columns(index).search(valueText).draw()
          }
        })
      })

      $(myTableApp).find('[data-dt-select-apply-toggle-button]').on('click', event => {
        const booleanBtn = $(event.currentTarget).attr('data-dt-select-apply-toggle-button') === 'true'
        dataTable.rows(':visible').every(rowIndex => {
          const trIndex = rowIndex
          $($(dataTable.rows(trIndex).nodes())).find('td[data-dt="dtThSelectsTdRadiosCol"]').each((indexTd, elTd) => {
            const $targetTd = $(elTd)
            const $targetInput = $(elTd).find('input').filter('[value=' + String(booleanBtn) + ']')

            const $targetRow = $targetTd.closest('tr')
            const cellIndex = $targetRow.find('td').index($targetTd)
            // const checkedVal = boolean
            const scrollPositionY = $(myTableApp).find('.dataTables_scrollBody').scrollTop()

            if (booleanBtn) {
              $targetRow.addClass('selected')
              $targetInput.prop('checked', true)
            } else {
              $targetRow.removeClass('selected')
              $targetInput.prop('checked', false)
            }

            dataTable.cell(dataTable.row(trIndex), cellIndex).data(booleanBtn)
            // dataTable.cell(dataTable.row(trIndex), cellIndex).data(booleanBtn).draw()
            $(myTableApp).find('.dataTables_scrollBody').scrollTop(scrollPositionY)
          })
        })
        dataTable.draw()
        event.stopPropagation()
      })
      this.dtAddRowIndex()
      this.dtTdUniformCategoryToggle()
      this.dtIsDisabledRowCheckboxToggle()
      this.dtThTdSticky()
      this.dtAddTdClass()
      this.dtElHeightAdjust()
      this.dtColTextSearch()
    }

    this.dtOnEventSet = function () {
      $(window).on('resize.dt', () => {
        setTimeout(() => {
          this.dtColumnsFilterChecked()
          // this.dtColumnsFilterFixed()
          this.dtTdOverflowToggle()
          this.dtHeadFixed()
          this.dtTopBottomScrollbarToggle()
          this.dtAdjust()
          this.dtThTdSticky()
          this.dtElHeightAdjust()
        }, 300)
      })
      $(window).on('scroll.dt', () => {
        // this.dtColumnsFilterFixed()
        this.dtHeadFixed()
        this.dtTopBottomScrollbarToggle()
      })

      const $dtColumnsFilter = $(myTableApp).find('.dtColumnsFilter')
      const $dtColumnsFilterCheckBox = $dtColumnsFilter.find('.dtColumnsFilterCheckBox')
      // $dtColumnsFilter.find('.dtColumnsFilterClip').on('click', () => {
      //   this.dtColumnsFilterFixed()
      // })

      $dtColumnsFilterCheckBox.find('input:not([value="ALL"])').on('change', ev => {
        dtFlowCheckbox()
        this.dtTdOverflowToggle()
        this.dtColumnsFilterChecked()
        $(myTable).DataTable().columns.adjust()
      })

      $dtColumnsFilterCheckBox.find('input[value="ALL"]').on('change', ev => {
        if ($dtColumnsFilterCheckBox.find('input[value="ALL"]').prop('checked')) {
          $dtColumnsFilterCheckBox.find('input').prop('checked', true)
        } else {
          $dtColumnsFilterCheckBox.find('.form-check-input').filter(':not([value="ALL"])').closest('.form-check').find('span').css({ opacity: '0.35' })
          $dtColumnsFilterCheckBox.find('input').prop('checked', false)
        }

        dtFlowCheckbox()
        this.dtTdOverflowToggle()
        this.dtColumnsFilterChecked()
        $(myTable).DataTable().columns.adjust()
      })
    }

    // 列表示タグ初期化
    this.dtColumnsFilterToggleInit = function () {
      const $dtColumnsFilterToggleBtn = $('<button type="button" class="btn btn-primary dtColumnsFilterToggleBtn px-2 py-1 ml-2 text-font-color" data-toggle="button" aria-pressed="false" autocomplete="off">列の表示を編集</button>')

      if (!this.settings.dtColumnsFilterToggleBtnDisplay) {
        $dtColumnsFilterToggleBtn.hide()
      }

      $(myTableApp).find('.dtColumnsFilterToggle').append($dtColumnsFilterToggleBtn)
    }

    // 列表示切り替えボタンイベント
    this.dtColumnsFilterToggleOnEventSet = function () {
      $(myTableApp).find('.dtColumnsFilterToggleBtn').on('click', () => {
        if ($(myTableApp).find('.dtColumnsFilter').css('display') === 'none') {
          $(myTableApp).find('.dtColumnsFilter').show()
        } else {
          $(myTableApp).find('.dtColumnsFilter').hide()
        }
      })

      $(myTableApp).find('.dtColumnsFilterClose').on('click', () => {
        $(myTableApp).find('.dtColumnsFilter').hide()
        $(myTableApp).find('.dtColumnsFilterToggleBtn').removeClass('active')
      })
      $(myTable).DataTable().columns.adjust().draw(false)
    }

    this.dtAjax = function () {
      return $.ajax({
        url: this.settings.dtAjax.url,
        type: 'POST',
        dataType: 'json',
        timeout: 30000,
        async: false,
        cache: false
      }).done((data, textStatus) => {
      // サーバがバリデーションエラーを返した場合
        if (data[0] === false) {
          return data
        }

        return data
      })
    }

    this.dtFlowInit = function () {
      this.dtTableInit()
      this.dtHeadColumnsSet()
      this.dtStart()
      this.dtColumnsFilterInit()
      this.dtColumnsFilterToggleInit()
      this.dtTdOverflowToggle()
      this.dtVariableWidthWatch()
      this.dtAdjust()
      // this.dtColumnsFilterFixed()
      this.dtColumnsFilterToggleOnEventSet()
      this.dtOnEventSet()
    }

    function dtFlowCheckbox() {
      const $dtColumnsFilter = $(myTableApp).find('.dtColumnsFilter')
      const $dtColumnsFilterCheckBox = $dtColumnsFilter.find('.dtColumnsFilterCheckBox')
      $dtColumnsFilterCheckBox.find('input:not([value="ALL"])').each((index, input) => {
        if ($(input).prop('checked')) {
          $(myTableApp).find('.dataTables_scrollHead').find('tr').each(function () {
            $(this).find('th,td').eq(index).show()
          })
          $(myTableApp).find('.dataTables_scrollBody').find('tr').each(function () {
            $(this).find('th,td').eq(index).show()
          })
        } else {
          $(myTableApp).find('.dataTables_scrollHead').find('tr').each(function () {
            $(this).find('th,td').eq(index).hide()
          })
          $(myTableApp).find('.dataTables_scrollBody').find('tr').each(function () {
            $(this).find('th,td').eq(index).hide()
          })
        }
      })
    }

    this.dtRun = function () {
      if (!$myTable.length) {
        return false
      }

      // if (this.settings.ajax) {
      //   this.settings.dtAjax = this.settings.ajax
      //   const result = this.dtAjax()
      //   delete this.settings.ajax
      //   if (result.responseJSON[0]) {
      //     this.settings.data = result.responseJSON
      //     this.dtFlowInit()
      //   } else {
      //     return false
      //   }
      // } else {
      this.dtFlowInit()
      // }
    }

    return this
  }
})(jQuery)
